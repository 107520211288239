<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <h2>
                    Выгрузить из базы
                  </h2>
                </v-col>
              </v-row>
              <v-radio-group v-model="downloadType">
                <v-row class="align-center">
                  <v-col cols="1">
                    <v-radio value="dump"></v-radio>
                  </v-col>
                  <DateRange
                    v-model="date"
                    title=""
                  />
                </v-row>
                <!--<v-row class="align-center">
                  <v-col cols="1">
                    <v-radio value="update"></v-radio>
                  </v-col>
                  <v-col cols="10">
                    <div>Последние изменения</div>
                  </v-col>
                </v-row>-->
              </v-radio-group>
              <v-row>
                <v-col cols="3">
                  <v-btn
                    @click="handleDownload"
                  >
                    <v-icon class="mr-2">mdi-cloud-download</v-icon>
                    Выгрузить
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card
          height="372"
          style="overflow-x: auto"
        >
          <v-card-text>
            <h2 class="pb-5">Очередь файлов для скачивания</h2>
            <div
              class="pa-3"
              v-for="file in files"
              :key="file.name"
            >
              <div class="d-flex align-center">
                <div><p class="caption pa-0 ma-0">{{file.name}}</p></div>
                <v-btn
                  small
                  class="primary ml-3"
                  :loading="!file.ready"
                  @click.prevent="handleCLick(file.name)"
                >Скачать
                </v-btn>
              </div>
              <hr class="grey lighten-5 ml-0 ma-5">
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DateRange from '@/components/views/admin/advert/Common/Controls/DateRange'

export default {
  name: 'Export',
  components: { DateRange },
  data: function () {
    return {
      date: [null, null],
      downloadType: 'dump'
    }
  },
  computed: {
    files () {
      return this.$store.getters['download/getAllFiles']
    }
  },
  methods: {
    async handleDownload () {
      await this.$store.dispatch('download/download', {
        payload: {
          url: 'lider',
          data: {
            type: this.downloadType,
            from: this.date[0],
            to: this.date[1]
          }
        }
      })
    },
    async handleCLick (fileName) {
      const response = await this.axios({
        url: '/files/' + fileName,
        method: 'GET',
        responseType: 'blob'
      })
      if (response) {
        let fileURL = window.URL.createObjectURL(new Blob([response.data]))
        let fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', fileName.split('/')[2])
        document.body.appendChild(fileLink)

        fileLink.click()
      }
    }
  },
  async mounted () {
    if (this.$store.getters['download/isChecking']) {
      await this.$store.dispatch('download/check')
    }
  }
}
</script>

<style scoped>

</style>
